<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        md="4"
      >
        <b-card title="Firma Bilgisi">
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-list-group>
                <b-list-group-item v-if="dataItem.company_name">
                  <div class="font-weight-bold text-primary">
                    <feather-icon icon="GlobeIcon" />
                    Firma Adı
                  </div>
                  <div>{{ dataItem.company_name }}</div>
                </b-list-group-item>
                <b-list-group-item v-if="dataItem.name_surname">
                  <div class="font-weight-bold text-primary">
                    <feather-icon icon="UserIcon" />
                    Adı Soyadı
                  </div>
                  <div>{{ dataItem.name_surname }}</div>
                </b-list-group-item>
                <b-list-group-item v-if="dataItem.phone">
                  <div class="font-weight-bold text-primary">
                    <feather-icon icon="PhoneIcon" />
                    Telefon Numarası
                  </div>
                  <div>{{ dataItem.phone }}</div>
                </b-list-group-item>
                <b-list-group-item v-if="dataItem.email">
                  <div class="font-weight-bold text-primary">
                    <feather-icon icon="MailIcon" />
                    Mail Adresi
                  </div>
                  <div>{{ dataItem.email }}</div>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <b-card>
          <template #header>
            <h4>Talep Bilgileri</h4>
            <div>
              <b-badge variant="light-secondary">
                <feather-icon icon="FileIcon" />
                {{ dataItem.webform }}
              </b-badge>
            </div>
          </template>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-list-group>
                <b-list-group-item v-if="dataItem.message">
                  <div class="font-weight-bold text-primary">
                    <feather-icon icon="MessageSquareIcon" />
                    Mesaj
                  </div>
                  <div>{{ dataItem.message }}</div>
                </b-list-group-item>
                <b-list-group-item v-if="dataItem.str_params_1">
                  <div class="font-weight-bold text-primary">
                    <feather-icon icon="FileTextIcon" />
                    Değer Alanı 1
                  </div>
                  <div>{{ dataItem.str_params_1 }}</div>
                </b-list-group-item>
                <b-list-group-item v-if="dataItem.str_params_2">
                  <div class="font-weight-bold text-primary">
                    <feather-icon icon="FileTextIcon" />
                    Değer Alanı 2
                  </div>
                  <div>{{ dataItem.str_params_2 }}</div>
                </b-list-group-item>
                <b-list-group-item v-if="dataItem.str_params_3">
                  <div class="font-weight-bold text-primary">
                    <feather-icon icon="FileTextIcon" />
                    Değer Alanı 3
                  </div>
                  <div>{{ dataItem.str_params_3 }}</div>
                </b-list-group-item>
                <b-list-group-item v-if="dataItem.reports">
                  <div class="font-weight-bold text-primary">
                    <feather-icon icon="PieChartIcon" />
                    Rapor Adı
                  </div>
                  <div>{{ dataItem.reports }}</div>
                </b-list-group-item>
                <b-list-group-item v-if="dataItem.datetime_params">
                  <div class="font-weight-bold text-primary">
                    <feather-icon icon="CalendarIcon" />
                    İndirilme / Açılma Tarihi
                  </div>
                  <div>{{ moment(dataItem.datetime_params).format('DD.MM.YYYY HH:mm:ss') }}</div>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <div class="mt-2">
                <b-badge
                  class="mr-1"
                  :variant="dataItem.kvkk === '1' ? 'light-success':'light-danger'"
                >
                  <feather-icon :icon="dataItem.kvkk === '1' ? 'CheckIcon':'XIcon'" />
                  KVKK
                </b-badge>
                <b-badge
                  class="mr-1"
                  :variant="dataItem.iys === '1' ? 'light-success':'light-danger'"
                >
                  <feather-icon :icon="dataItem.iys === '1' ? 'CheckIcon':'XIcon'" />
                  IYS
                </b-badge>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BListGroup, BListGroupItem, BCard, BRow, BCol, BBadge,
} from 'bootstrap-vue'

export default {
  name: 'View',
  components: {
    BListGroupItem,
    BListGroup,
    BCard,
    BRow,
    BCol,
    BBadge,
  },
  computed: {
    dataItem() {
      return this.$store.getters['webformRequests/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      return this.$store.dispatch('webformRequests/getDataItem', this.$route.params.id)
    },
  },
}
</script>
